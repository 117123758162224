import * as React from "react";
import {
  Grid as ChakraGrid
} from "@chakra-ui/react";

import {
  Project
} from "./Items";


interface Props {
  nodes: any[]
  template: string
}


export const Grid: React.FC<Props> = ( props ) => {
  const nodeList = props.nodes.map( ( node: any, _index: number ) => {
    switch ( props.template ) {
      case 'project':
        return (
          <Project
            key={ _index }
            node={ node.node }
          />
        );
      default:
        return null;
    }
  });

  return (
    <ChakraGrid
      templateColumns={{
        base: '1fr',
        md: '1fr 1fr',
        lg: '1fr 1fr 1fr'
      }}
      gap="24px"
    >
      { nodeList }
    </ChakraGrid>
  )
}
