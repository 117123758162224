import * as React from "react";
import {
  Tab,
  Tabs as ChakraTabs,
  TabList,
  TabPanel,
  TabPanels,
  VStack
} from "@chakra-ui/react";

import {
  ProjectArea
} from "./Panels";


interface Props {
  panels: any[]
  template: string
}


export const Tabs: React.FC<Props> = ( props ) => {
  const panelList = props.panels.map( ( nodes: any[], _index: number ) => {
    switch ( props.template ) {
      case 'project':
        return (
          <TabPanel
            key={ _index }
            padding="32px 0"
          >
            <ProjectArea
              nodes={ nodes }
            />
          </TabPanel>
        );
      default:
        return null;
    }
  });

  return (
    <VStack
      justify="flex-start"
      align="flex-start"
      w="100%"
    >
      <ChakraTabs>
        <TabList
          borderBottom="0"
          gap={{
            base: '16px',
            sm: '0'
          }}
          flexWrap="wrap"
        >
          <Tab
            border="1px"
            borderRadius={{
              base: '21px',
              sm: '21px 0 0 21px'
            }}
            _selected={{
              bg: 'primary.900',
              borderColor: 'primary.900',
              color: 'white'
            }}
          >
            Laneway
          </Tab>
          <Tab
            border="1px"
            borderRadius={{
              base: '21px',
              sm: '0'
            }}
            marginLeft="-1px"
            _selected={{
              bg: 'primary.900',
              borderColor: 'primary.900',
              color: 'white'
            }}
          >
            Underpinning
          </Tab>
          <Tab
            border="1px"
            borderRadius={{
              base: '21px',
              sm: '0'
            }}
            marginLeft="-1px"
            _selected={{
              bg: 'primary.900',
              borderColor: 'primary.900',
              color: 'white'
            }}
          >
            Waterproofing
          </Tab>
          <Tab
            border="1px"
            borderRadius={{
              base: '21px',
              sm: '0'
            }}
            marginLeft="-1px"
            _selected={{
              bg: 'primary.900',
              borderColor: 'primary.900',
              color: 'white'
            }}
          >
            Concrete Work
          </Tab>
          <Tab
            border="1px"
            borderRadius={{
              base: '21px',
              sm: '0 21px 21px 0'
            }}
            marginLeft="-1px"
            _selected={{
              bg: 'primary.900',
              borderColor: 'primary.900',
              color: 'white'
            }}
          >
            Sump Pump
          </Tab>
        </TabList>
        <TabPanels>
          { panelList }
        </TabPanels>
      </ChakraTabs>
    </VStack>
  )
}
