import * as React from "react";

import { Grid } from "../../Grid";


interface Props {
  nodes: any[]
}


export const ProjectArea: React.FC<Props> = ( props ) => {
  return (
    <Grid
      nodes={ props.nodes }
      template="project"
    />
  )
}
